import React from 'react';

const IconLogo = () => (
 <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 5 30 22">
    <title>Logo</title>
    <g transform="translate(-5.000000, -2.000000)">
      <g transform="translate(5.000000, 8.000000)">
        <path
          d="M4.37166667,7.23916667 C4.49666667,6.99333333 4.3025,6.75083333 4.11916667,6.58583333 L2.2525,4.33583333 L2.2525,4 L8.05083333,4 L12.5325,13.8291667 L16.4725,4 L22,4 L22,4.33583333 L20.4033333,5.86666667 C20.2658333,5.97166667 20.1975,6.14416667 20.2258333,6.315 L20.2258333,17.5633333 C20.1975,17.7333333 20.2658333,17.9058333 20.4033333,18.0108333 L21.9625,19.5416667 L21.9625,19.8775 L14.1191667,19.8775 L14.1191667,19.5416667 L15.735,17.9733333 C15.8933333,17.815 15.8933333,17.7683333 15.8933333,17.5258333 L15.8933333,8.43416667 L11.4025,19.8408333 L10.7958333,19.8408333 L5.56666667,8.43416667 L5.56666667,16.0791667 C5.52333333,16.4 5.63,16.7241667 5.85583333,16.9558333 L7.95666667,19.5041667 L7.95666667,19.8408333 L2,19.8408333 L2,19.5041667 L4.10083333,16.9558333 C4.32583333,16.7233333 4.42583333,16.3975 4.37166667,16.0791667 L4.37166667,7.23916667 L4.37166667,7.23916667 Z"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
